import { NgModule } from '@angular/core';

import { PrivaActionMenuModule } from '@priva/components/action-menu';
import { PrivaButtonModule } from '@priva/components/button';
import { PrivaHeaderModule } from '@priva/components/header';
import { PrivaLocalizationModule } from '@priva/localization';

import { StatusErrorPageComponent } from './status-error-page.component';

@NgModule({
    imports: [PrivaActionMenuModule, PrivaButtonModule, PrivaHeaderModule, PrivaLocalizationModule],
    declarations: [StatusErrorPageComponent],
    exports: [StatusErrorPageComponent],
})
export class StatusErrorPageModule {}
